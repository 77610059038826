<template>
  <section class="sign-up h-100">
    <div class="flex h-screen">
      <div class="m-auto lg:ml-40" v-if="!codeSent">
        <div class="mb-5">
          <h3 class="text align-self-center mr-auto">
            Reset Password
          </h3>

          <p class="text-sm align-self-center mr-auto">
            Please enter the email address associated with your account. <br/> We will send you an email with an authentification code.
          </p>
        </div>

        <form
          class="mt-10"
          @submit.prevent="login"
        >
          <div
            v-if="errorMessage"
            class="flex items-center text-white text-sm font-bold mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Username or Email"
              required
            >
          </div>

          <div class="mt-7">
            <button
              type="button"
              class="btn btn-primary-outline"
              @click="sendCode()"
            >
              Send me my code
            </button>
          </div>
        </form>
      </div>

      <div class="m-auto lg:ml-40" v-if="codeSent">
        <div class="mb-5">
          <h3 class="text align-self-center mr-auto">
            Reset Password
          </h3>

          <p class="text-sm align-self-center mr-auto">
            Please type you authentification code below.
          </p>
        </div>

        <form
          class="mt-10"
          @submit.prevent="login"
        >
          <div
            v-if="errorMessage"
            class="flex items-center text-white text-sm font-bold mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Enter Code"
              required
            >
          </div>

          <div class="mt-7">
            <button
              type="submit"
              class="btn btn-primary-outline"
            >
              Validate my code
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ForgotPassword',
  data() {
    return {
      codeSent: false,
      email: "",
      password: "",
      errorMessage: null
    };
  },
  methods: {
    sendCode: function() {
      this.codeSent = !this.codeSent;
    }
  },
}
</script>

<style scoped>
.sign-up {
  background-image: url('~@/assets/images/onboarding.jpg');
  background-size: cover;
  background-position: 50%;
  height: 100vh!important;
}
h3 {
  font-family: 'Helvetica';
  font-size: 34px;
  line-height: 50px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 400;
}
h3 > strong {
  font-weight: bold;
}
.sign-up p {
  font-family: 'Helvetica';
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 0;
  font-weight: 300;
}
.sign-up .social-links {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 45px;
  padding: 10px;
  min-height: 50px;
}
.sign-up .social-links img {
  height: 24px;
}

/* FIX: Input must be a component */
input {
  background: rgba(0, 0, 0, 0.5)!important;
  color: #FFFFFF!important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  min-width: 250px;
}
input::placeholder {
  color: #FFFFFF!important;
  opacity: 1!important;
}
input:-ml-input-placeholder {
  color: #FFFFFF!important;
}
input::-ml-input-placeholder {
  color: #FFFFFF!important;
}
.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
.btn-primary-outline {
  background-color: transparent;
  color: #FF6600;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
.alert-error {
  background: rgba(217, 54, 31, 0.3);
  border-radius: 10px;
  padding: 10px;
}
.alert-error p {
  font-size: 14px;
}
</style>
